/*
 * @name: 张梦琳
 * @test: test font
 * @Date: 2021-01-27 10:28:04
 * @FilePath: \web_bcrm\src\api\ceshi_xiugai\Sendcustomerstohighseas.js
 * @msg:
 * @param:
 * @return:
 */
import request from '@/utils/request';
/*-------------------zml接口---------------------------- */
//外送公海列表
export function MyLiberumList(data) {
  return request({
    url: '/clientserver.php/MyLiberum/index',
    method: 'post',
    data: data
  });
}
//领取zml
export function MyLiberumreceive(data) {
  return request({
    url: '/clientserver.php/MyLiberum/receive',
    method: 'post',
    data: data
  });
}
//分配zml
export function MyLiberumdistribution(data) {
  return request({
    url: '/clientserver.php/MyLiberum/distribution',
    method: 'post',
    data: data
  });
}