import "core-js/modules/es6.regexp.to-string";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mailListUp from '@/components/StaffSelect/mailListRadioUp';
import Head from '@/components/head/index';
import { getcustomerlevel, getStructureListArr } from '@/api/PublicAPI';
import { MyLiberumList, MyLiberumreceive, MyLiberumdistribution } from '@/api/ceshi_xiugai/Sendcustomerstohighseas';
import { agentFlowerNameList, Majorlist, getLabelpeer as _getLabelpeer, FollwupIndex } from '@/api/ceshi_xiugai/whole';
export default {
  components: {
    Head: Head,
    mailListUp: mailListUp
  },
  data: function data() {
    return {
      //系统教程
      tab: 0,
      course: this.$route.query.course,
      prohibit: false,
      //系统教程
      loading: true,
      flower_id: '',
      //学员来源id
      flower_Array: [],
      //学员来源
      title: '外送公海客户池',
      value1: '',
      get_type: 6,
      query: {
        page: 1,
        pagesize: 10,
        total: 0 //总条数，不是总页数
      },
      customer_id: '',
      customer: [],
      //学员级别
      wxnum_mobile_cname: '',
      //	微信/手机号/学员称呼
      tableData: [],
      Alltableoptions: [],
      staffSelectVisible: false,
      staffResult: [],
      staffDisableArr: [],
      condition: [],
      radio: false,
      tempId: '',
      editForm: {
        type: 1,
        customer_levels: '',
        //学员级别（传id）
        source_client_id: '',
        //花名（学员来源） 传id
        create_stime: '',
        //创建开始时间
        create_etime: '',
        //创建结束时间
        ittnzy: '',
        //意向专业（1,2,3）
        next_follow_stime: '',
        //下次回访日期开始时间
        next_follow_etime: '',
        //下次回访日期结束时间
        start_lib_time: '',
        end_lib_time: '',
        create_realname: '',
        //创建人
        structure_ids: '',
        //原始部门 （1,2,3）
        labelpeers: '',
        //客户标签(1,2,3)
        notes: '',
        //备注
        create_time_order: '',
        //创建日期排序 1正序 2倒序 0不排序
        follow_time_order: '',
        //最后跟进日期排序 1正序 2倒序 0不
        next_time_order: '',
        //下次回访日期排序 1正序 2倒序 0不排
        wxnum_mobile_cname: '',
        //手机号 微信 qq号 用户名
        paginate: '',
        page: '',
        mobile_address: ''
      },
      finalFollowshow: false,
      attributionshow: false,
      nexttimeshow: false,
      //下次回访日期
      lasttimeshow: false,
      //最后跟进日期
      createtimeshow: false,
      //创建日期
      ittnzyshow: false,
      //意向专业
      createnameshow: false,
      //创建人
      remarksshow: false,
      //备注
      labelpeersshow: false,
      //客户标签
      structure_idshow: false,
      //组织部门

      attribution: '',
      createtime: '',
      //创建日期
      showittnzy: '',
      //意向专业
      Intendeds: [],
      //意向专业
      nexttime: '',
      //下次回访日期
      finalFollow: '',
      //最后跟进日期
      create_realname: '',
      //创建人
      structure_id: '',
      //组织部门
      bumenoptions: [],
      labelpeers: '',
      //客户标签
      labelpeersEs: [],
      remarks: '',
      //备注
      followUp: false,
      //跟进弹窗
      activities: [],
      reverse: true
    };
  },
  mounted: function mounted() {
    if (this.course != 1) {
      this.Getcustomer();
      this.majorDedMaj(); //意向专业
      this.getStructure(); //组织部门
      this.getLabelpeer();
    } else {}
  },
  created: function created() {
    if (this.course != 1) {
      this.GetList();
      this.flowerArray();
    }
  },
  methods: {
    //学员级别list
    Getcustomer: function Getcustomer() {
      var _this = this;
      getcustomerlevel().then(function (res) {
        _this.customer = res.data;
      });
    },
    flowerArray: function flowerArray() {
      var _this2 = this;
      //花名
      agentFlowerNameList().then(function (res) {
        _this2.flower_Array = res.data;
      });
    },
    getStructure: function getStructure() {
      var _this3 = this;
      //组织部门
      getStructureListArr().then(function (respomse) {
        _this3.bumenoptions = respomse.data;
      });
    },
    majorDedMaj: function majorDedMaj() {
      var _this4 = this;
      //意向专业默认
      Majorlist().then(function (res) {
        _this4.Intendeds = res.data.major_list;
      });
    },
    // 客户标签
    getLabelpeer: function getLabelpeer() {
      var _this5 = this;
      _getLabelpeer().then(function (res) {
        //客户标签
        res.data.map(function (itemEs) {
          itemEs.id = String(itemEs.id);
        });
        _this5.labelpeersEs = res.data;
        // this.customer = res.data;
      });
    },
    GetList: function GetList() {
      var _this6 = this;
      this.editForm.paginate = this.query.pagesize;
      this.editForm.page = this.query.page;
      this.loading = true;
      MyLiberumList(this.editForm).then(function (res) {
        _this6.tableData = res.data.data;
        _this6.loading = false;
        _this6.query.total = res.data.total;
      });
    },
    //学员级别查询
    customerhand: function customerhand(val) {
      var share = val.map(function (e) {
        return e[e.length - 1];
      }).toString(); // 学员级别的最后一位ID
      this.editForm.customer_levels = share;
      this.query.page = 1;
      this.GetList();
    },
    //学员来源查询
    flowerArrayEs: function flowerArrayEs() {
      var _this7 = this;
      this.query.page = 1;
      if (this.flower_id !== '') {
        this.editForm.source_client_id = this.flower_id;
        this.$nextTick(function () {
          _this7.GetList();
        });
      } else {
        this.editForm.source_client_id = '';
        this.$nextTick(function () {
          _this7.GetList();
        });
      }
    },
    //查询
    handleFilter: function handleFilter() {
      var _this8 = this;
      this.query.page = 1;
      this.loading = true;
      MyLiberumList({
        pagesize: this.query.pagesize,
        page: this.query.page,
        wxnum_mobile_cname: this.wxnum_mobile_cname,
        type: 1
      }).then(function (res) {
        _this8.tableData = res.data.data;
        _this8.total = res.data.total;
        _this8.loading = false;
      });
    },
    //每页最多显示条数10   20   30   50
    handleSizeChange: function handleSizeChange(val) {
      var _this9 = this;
      this.query.pagesize = val;
      this.$nextTick(function () {
        _this9.GetList();
      });
    },
    //翻页
    handleCurrentChange: function handleCurrentChange(val) {
      var _this10 = this;
      this.query.page = val;
      this.$nextTick(function () {
        _this10.GetList();
      });
    },
    selsChange: function selsChange(val) {
      this.Alltableoptions = val;
      this.tempId = this.Alltableoptions.map(function (item) {
        return item.customer_data_id;
      }).join(',');
    },
    //领取
    Receive: function Receive() {
      var _this11 = this;
      if (this.Alltableoptions.length !== 0) {
        this.$confirm('确认领取该记录吗?', '提示', {
          type: 'warning'
        }).then(function () {
          MyLiberumreceive({
            customer_data_ids: String(_this11.tempId)
          }).then(function (res) {
            _this11.$notify({
              title: '提示',
              message: res.data.toString(),
              type: 'success',
              duration: 3000
            });
            _this11.$nextTick(function () {
              //获取列表方法
              _this11.GetList();
            });
          });
        }).catch(function () {});
      } else {
        this.$message('请选择领取项');
      }
    },
    //分配
    Distribution: function Distribution() {
      if (this.Alltableoptions.length !== 0) {
        this.staffSelectVisible = true;
        this.radio = true;
      } else {
        this.$message('请选中移交项');
      }
    },
    staffSelectConfirm: function staffSelectConfirm(resultNameArr, result) {
      var _this12 = this;
      if (this.Alltableoptions.length !== 0) {
        this.$confirm('确认分配该记录吗?', '提示', {
          type: 'warning'
        }).then(function () {
          var para = {
            customer_data_ids: String(_this12.tempId),
            user_id: result.toString()
          };
          MyLiberumdistribution(para).then(function (res) {
            _this12.$notify({
              title: '提示',
              message: res.data.toString(),
              type: 'success',
              duration: 3000
            });
            _this12.$nextTick(function () {
              //获取列表方法
              _this12.GetList();
            });
          });
        }).catch(function () {});
      } else {
        this.$message('请选中分配项');
      }
    },
    //表头显示搜索框
    Activeshow: function Activeshow(item) {
      if (item === '下次回访日期') {
        this.nexttimeshow = true;
      } else if (item === '创建日期') {
        this.createtimeshow = true;
      } else if (item === '意向专业') {
        this.ittnzyshow = true;
      } else if (item === '创建人') {
        this.createnameshow = true;
      } else if (item === '备注') {
        this.remarksshow = true;
      } else if (item === '组织部门') {
        this.structure_idshow = true;
      } else if (item == '客户标签') {
        this.labelpeersshow = true;
      } else if (item == '最后跟进日期') {
        this.finalFollowshow = true;
      } else if (item == '归属地') {
        this.attributionshow = true;
      }
    },
    //点×
    Deletesshow: function Deletesshow(item) {
      var _this13 = this;
      this.query.page = 1;
      if (item === '下次回访日期') {
        this.nexttimeshow = false;
        this.editForm.next_follow_stime = '';
        this.editForm.next_follow_etime = '';
        this.nexttime = '';
        this.$nextTick(function () {
          _this13.GetList(_this13.editForm.page, _this13.editForm.pagesize);
        });
      } else if (item === '最后跟进日期') {
        this.finalFollowshow = false;
        this.editForm.start_follow_time = '';
        this.editForm.end_follow_time = '';
        this.finalFollow = '';
        this.GetList(this.editForm.page, this.editForm.pagesize);
      } else if (item === '创建日期') {
        this.createtimeshow = false;
        this.editForm.create_stime = '';
        this.editForm.create_etime = '';
        this.createtime = '';
        this.GetList(this.editForm.page, this.editForm.pagesize);
      } else if (item === '意向专业') {
        this.ittnzyshow = false;
        this.showittnzy = '';
        this.editForm.ittnzy = '';
        this.GetList(this.editForm.page, this.editForm.pagesize);
      } else if (item === '创建人') {
        this.createnameshow = false;
        this.editForm.create_realname = '';
        this.$nextTick(function () {
          _this13.GetList(_this13.editForm.page, _this13.editForm.pagesize);
        });
      } else if (item === '备注') {
        this.remarksshow = false;
        this.remarks = '';
        this.editForm.notes = '';
        this.GetList(this.editForm.page, this.editForm.pagesize);
      } else if (item === '组织部门') {
        this.structure_idshow = false;
        this.editForm.structure_ids = '';
        this.structure_ids = '';
        this.GetList(this.editForm.page, this.editForm.pagesize);
      } else if (item === '客户标签') {
        this.labelpeersshow = false;
        this.labelpeers = '';
        this.editForm.labelpeers = '';
        this.GetList(this.editForm.page, this.editForm.pagesize);
      } else if (item === '归属地') {
        this.attributionshow = false;
        this.attribution = '';
        this.editForm.mobile_address = '';
        this.GetList(this.editForm.page, this.editForm.pagesize);
      }
    },
    //创建人
    createname: function createname() {
      this.editForm.create_realname = this.create_realname;
      this.query.page = 1;
      this.GetList(this.editForm.page, this.editForm.pagesize);
    },
    //创建时间
    creatDateTime: function creatDateTime() {
      this.query.page = 1;
      if (this.createtime == null) {
        this.editForm.create_stime = '';
        this.editForm.create_etime = '';
        this.GetList(this.editForm.page, this.editForm.pagesize);
      } else {
        this.editForm.create_stime = this.createtime[0].toString().substring(0, 10);
        this.editForm.create_etime = this.createtime[1].toString().substring(0, 10);
        this.GetList(this.editForm.page, this.editForm.pagesize);
      }
    },
    //回访时间选择
    nextDateTime: function nextDateTime(item) {
      this.query.page = 1;
      if (this.nexttime == null) {
        this.editForm.next_follow_stime = '';
        this.editForm.next_follow_etime = '';
        this.GetList(this.editForm.page, this.editForm.pagesize);
      } else {
        this.editForm.next_follow_stime = this.nexttime[0].toString().substring(0, 10);
        this.editForm.next_follow_etime = this.nexttime[1].toString().substring(0, 10);
        this.GetList(this.editForm.page, this.editForm.pagesize);
      }
    },
    //最后跟进日期筛选
    FinalFollowUpEs: function FinalFollowUpEs() {
      this.query.page = 1;
      if (this.finalFollow == null) {
        this.editForm.start_follow_time = '';
        this.editForm.end_follow_time = '';
        this.GetList(this.editForm.page, this.editForm.pagesize);
      } else {
        this.editForm.start_follow_time = this.finalFollow[0].toString().substring(0, 10);
        this.editForm.end_follow_time = this.finalFollow[1].toString().substring(0, 10);
        this.GetList(this.editForm.page, this.editForm.pagesize);
      }
    },
    //意向专业
    shouwittnzyclick: function shouwittnzyclick() {
      this.editForm.ittnzy = this.showittnzy;
      this.query.page = 1;
      this.GetList(this.editForm.page, this.editForm.pagesize);
    },
    //组织部门
    structureclick: function structureclick(val) {
      var share = val.map(function (e) {
        return e[e.length - 1];
      }).toString(); // 部门的最后一位ID
      var adad = share == ',' ? '' : share;
      this.editForm.structure_ids = adad;
      this.query.page = 1;
      this.GetList(this.editForm.page, this.editForm.pagesize);
    },
    customerLabel: function customerLabel(item) {
      //客户标签筛选
      this.editForm.labelpeers = item;
      this.query.page = 1;
      this.GetList(this.editForm.page, this.editForm.pagesize);
    },
    //备注
    remarksClick: function remarksClick() {
      this.editForm.notes = this.remarks;
      this.query.page = 1;
      this.GetList(this.editForm.page, this.editForm.pagesize);
    },
    attributionClick: function attributionClick() {
      this.editForm.mobile_address = this.attribution;
      this.query.page = 1;
      this.GetList(this.editForm.page, this.editForm.pagesize);
    },
    sortEs: function sortEs(column, prop, order) {
      // ascending 升序 、 descending降序 、 null 不排序
      this.query.page = 1;
      if (column.prop == 'create_time') {
        //创建日期
        if (column.order == 'ascending') {
          this.editForm.create_time_order = '1';
        } else if (column.order == 'descending') {
          this.editForm.create_time_order = '2';
        } else {
          this.editForm.create_time_order = '0';
        }
        this.editForm.follow_time_order = '0';
        this.editForm.next_time_order = '0';
      } else if (column.prop == 'follw_time') {
        //最后跟进日期
        if (column.order == 'ascending') {
          this.editForm.follow_time_order = '1';
        } else if (column.order == 'descending') {
          this.editForm.follow_time_order = '2';
        } else {
          this.editForm.follow_time_order = '0';
        }
        this.editForm.create_time_order = '0';
        this.editForm.next_time_order = '0';
      } else if (column.prop == 'next_time') {
        //下次回访日期
        if (column.order == 'ascending') {
          this.editForm.next_time_order = '1';
        } else if (column.order == 'descending') {
          this.editForm.next_time_order = '2';
        } else {
          this.editForm.next_time_order = '0';
        }
        this.editForm.create_time_order = '0';
        this.editForm.follow_time_order = '0';
      }
      this.GetList(this.editForm.page, this.editForm.pagesize);
    },
    followUpCustomers: function followUpCustomers(id) {
      var _this14 = this;
      //跟进弹窗
      // this.followUpEs.type = 1;
      this.followUp = true;
      // this.followUpEs.name = id.cname;
      // this.followUpId = id.customer_id;
      // this.followUpIdES = id.customer_data_id;
      FollwupIndex({
        id: id.customer_id,
        customer_data_id: id.customer_data_id,
        is_all: 1,
        is_realname: 0
      }).then(function (res) {
        _this14.activities = res.data;
      });
    }
  }
};