var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-container", { staticClass: "app-container" }, [
    _vm.course != 1
      ? _c(
          "div",
          { staticStyle: { width: "100%" } },
          [
            _c(
              "el-header",
              { staticStyle: { height: "auto" } },
              [
                _c(
                  "el-row",
                  {
                    staticStyle: { height: "30px", margin: "10px 0px" },
                    attrs: { gutter: 24 },
                  },
                  [_c("Head", { attrs: { name: _vm.title } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 10 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { xs: 6, sm: 4, md: 7, lg: 7, xl: 4 } },
                      [
                        _vm._v(
                          "\n                    学员级别:\n                    "
                        ),
                        _c("el-cascader", {
                          staticStyle: { width: "180px" },
                          attrs: {
                            filterable: "",
                            options: _vm.customer,
                            props: {
                              multiple: true,
                              value: "id",
                              label: "name",
                            },
                            clearable: "",
                            "collapse-tags": "",
                            size: "small",
                          },
                          on: { change: _vm.customerhand },
                          model: {
                            value: _vm.customer_id,
                            callback: function ($$v) {
                              _vm.customer_id = $$v
                            },
                            expression: "customer_id",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 6, sm: 4, md: 7, lg: 7, xl: 4 } },
                      [
                        _vm._v(
                          "\n                    学员来源:\n                    "
                        ),
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "180px" },
                            attrs: {
                              size: "small",
                              filterable: "",
                              placeholder: "请搜索花名",
                              clearable: "",
                            },
                            on: { change: _vm.flowerArrayEs },
                            model: {
                              value: _vm.flower_id,
                              callback: function ($$v) {
                                _vm.flower_id = $$v
                              },
                              expression: "flower_id",
                            },
                          },
                          _vm._l(_vm.flower_Array, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: {
                                label: item.flower_name,
                                value: item.id,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 4, sm: 3, md: 4, lg: 4, xl: 3 } },
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["领取"],
                                expression: "['领取']",
                              },
                            ],
                            attrs: { size: "small", type: "primary" },
                            on: { click: _vm.Receive },
                          },
                          [_vm._v("领取")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["分配"],
                                expression: "['分配']",
                              },
                            ],
                            attrs: { size: "small", type: "primary" },
                            on: { click: _vm.Distribution },
                          },
                          [_vm._v("分配")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticStyle: { float: "right" },
                        attrs: { xs: 5, sm: 5, md: 5, lg: 5, xl: 5 },
                      },
                      [
                        _c(
                          "el-input",
                          {
                            staticClass: "input-with-select",
                            attrs: {
                              placeholder: "请输入微信/手机号/用户名/QQ查询",
                              size: "small",
                              clearable: "",
                            },
                            on: { change: _vm.handleFilter },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.handleFilter($event)
                              },
                            },
                            model: {
                              value: _vm.wxnum_mobile_cname,
                              callback: function ($$v) {
                                _vm.wxnum_mobile_cname = $$v
                              },
                              expression: "wxnum_mobile_cname",
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                slot: "append",
                                type: "primary",
                                size: "small",
                                icon: "el-icon-search",
                              },
                              on: { click: _vm.handleFilter },
                              slot: "append",
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 24 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.createtimeshow,
                                expression: "createtimeshow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("创建日期:"),
                            ]),
                            _vm._v(" "),
                            _c("el-date-picker", {
                              staticStyle: { width: "234px !important" },
                              attrs: {
                                size: "small",
                                type: "datetimerange",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "  结束日期",
                                "default-time": ["00:00:00", "23:59:59"],
                                "value-format": "timestamp",
                              },
                              on: { change: _vm.creatDateTime },
                              model: {
                                value: _vm.createtime,
                                callback: function ($$v) {
                                  _vm.createtime = $$v
                                },
                                expression: "createtime",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("创建日期")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.ittnzyshow,
                                expression: "ittnzyshow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("意向专业："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  filterable: "",
                                  placeholder: "请选择",
                                  size: "small",
                                  clearable: "",
                                },
                                on: { change: _vm.shouwittnzyclick },
                                model: {
                                  value: _vm.showittnzy,
                                  callback: function ($$v) {
                                    _vm.showittnzy = $$v
                                  },
                                  expression: "showittnzy",
                                },
                              },
                              _vm._l(_vm.Intendeds, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.title, value: item.id },
                                })
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("意向专业")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.finalFollowshow,
                                expression: "finalFollowshow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("最后跟进日期:"),
                            ]),
                            _vm._v(" "),
                            _c("el-date-picker", {
                              staticStyle: { width: "234px !important" },
                              attrs: {
                                size: "small",
                                type: "datetimerange",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "  结束日期",
                                "default-time": ["00:00:00", "23:59:59"],
                                "value-format": "timestamp",
                              },
                              on: { change: _vm.FinalFollowUpEs },
                              model: {
                                value: _vm.finalFollow,
                                callback: function ($$v) {
                                  _vm.finalFollow = $$v
                                },
                                expression: "finalFollow",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("最后跟进日期")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.nexttimeshow,
                                expression: "nexttimeshow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("下次回访日期:"),
                            ]),
                            _vm._v(" "),
                            _c("el-date-picker", {
                              staticStyle: { width: "234px !important" },
                              attrs: {
                                size: "small",
                                type: "datetimerange",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "  结束日期",
                                "default-time": ["00:00:00", "23:59:59"],
                                "value-format": "timestamp",
                              },
                              on: { change: _vm.nextDateTime },
                              model: {
                                value: _vm.nexttime,
                                callback: function ($$v) {
                                  _vm.nexttime = $$v
                                },
                                expression: "nexttime",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("下次回访日期")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.createnameshow,
                                expression: "createnameshow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("创建人:"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                staticStyle: { width: "250px" },
                                attrs: {
                                  placeholder: "请搜索电话、名称",
                                  size: "small",
                                },
                                on: { change: _vm.createname },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.createname($event)
                                  },
                                },
                                model: {
                                  value: _vm.create_realname,
                                  callback: function ($$v) {
                                    _vm.create_realname = $$v
                                  },
                                  expression: "create_realname",
                                },
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    slot: "append",
                                    type: "primary",
                                    size: "small",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.createname },
                                  slot: "append",
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("创建人")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.structure_idshow,
                                expression: "structure_idshow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("组织部门:"),
                            ]),
                            _vm._v(" "),
                            _c("el-cascader", {
                              attrs: {
                                placeholder: "请选择部门",
                                options: _vm.bumenoptions,
                                props: {
                                  value: "id",
                                  label: "name",
                                  multiple: true,
                                },
                                size: "small",
                                "collapse-tags": "",
                                filterable: "",
                                clearable: "",
                              },
                              on: { change: _vm.structureclick },
                              model: {
                                value: _vm.structure_id,
                                callback: function ($$v) {
                                  _vm.structure_id = $$v
                                },
                                expression: "structure_id",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("组织部门")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.labelpeersshow,
                                expression: "labelpeersshow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("客户标签:"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  size: "small",
                                  placeholder: "请选择",
                                  clearable: "",
                                  filterable: "",
                                },
                                on: { change: _vm.customerLabel },
                                model: {
                                  value: _vm.labelpeers,
                                  callback: function ($$v) {
                                    _vm.labelpeers = $$v
                                  },
                                  expression: "labelpeers",
                                },
                              },
                              _vm._l(_vm.labelpeersEs, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.value, value: item.id },
                                })
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("客户标签")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.remarksshow,
                                expression: "remarksshow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("备注:"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                staticStyle: { width: "250px" },
                                attrs: {
                                  size: "small",
                                  placeholder: "请搜索备注",
                                },
                                on: { change: _vm.remarksClick },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.remarksClick($event)
                                  },
                                },
                                model: {
                                  value: _vm.remarks,
                                  callback: function ($$v) {
                                    _vm.remarks = $$v
                                  },
                                  expression: "remarks",
                                },
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    slot: "append",
                                    type: "primary",
                                    size: "small",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.remarksClick },
                                  slot: "append",
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("备注")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.attributionshow,
                                expression: "attributionshow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "nameTitle projectScreening" },
                              [_vm._v("归属地：")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                staticStyle: { width: "250px" },
                                attrs: {
                                  placeholder: "多个归属地以空格分隔",
                                  size: "small",
                                },
                                model: {
                                  value: _vm.attribution,
                                  callback: function ($$v) {
                                    _vm.attribution = $$v
                                  },
                                  expression: "attribution",
                                },
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    slot: "append",
                                    type: "primary",
                                    size: "small",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.attributionClick },
                                  slot: "append",
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("归属地")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-main",
              [
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      ref: "tb",
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.tableData,
                        "tooltip-effect": "dark",
                        border: "",
                        "header-cell-style": {
                          background: "#F9F9F9",
                          color: "#222222",
                          textAlign: "center",
                        },
                        "cell-style": { textAlign: "center" },
                      },
                      on: {
                        "sort-change": _vm.sortEs,
                        "selection-change": _vm.selsChange,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "selection", width: "50" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "序号", width: "50" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        scope.$index +
                                          (_vm.query.page - 1) *
                                            _vm.query.pagesize +
                                          1
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3946342523
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "客户名称", prop: "cname" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "#1890FF",
                                        cursor: "pointer",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.followUpCustomers(
                                            scope.row
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(scope.row.cname))]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          4205619702
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "电话号码",
                          width: "120",
                          prop: "mobile",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-table-column",
                        {
                          key: 8,
                          attrs: {
                            label: "归属地",
                            prop: "mobile_address",
                            align: "center",
                          },
                        },
                        [
                          _c("template", { slot: "header" }, [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  cursor: "pointer",
                                  color: "#1890FF",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.Activeshow("归属地")
                                  },
                                },
                              },
                              [_vm._v("归属地")]
                            ),
                          ]),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "el-table-column",
                        {
                          attrs: {
                            label: "创建日期",
                            sortable: "custom",
                            width: "150",
                            prop: "create_time",
                            align: "center",
                            filters: [],
                          },
                        },
                        [
                          _c("template", { slot: "header" }, [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  cursor: "pointer",
                                  color: "#1890FF",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.Activeshow("创建日期")
                                  },
                                },
                              },
                              [_vm._v("创建日期")]
                            ),
                          ]),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "el-table-column",
                        {
                          attrs: {
                            label: "下次回访日期",
                            sortable: "custom",
                            width: "160",
                            prop: "next_time",
                            align: "center",
                            filters: [],
                          },
                        },
                        [
                          _c("template", { slot: "header" }, [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  cursor: "pointer",
                                  color: "#1890FF",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.Activeshow("下次回访日期")
                                  },
                                },
                              },
                              [_vm._v("下次回访日期")]
                            ),
                          ]),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "el-table-column",
                        {
                          attrs: {
                            label: "最后跟进日期",
                            sortable: "custom",
                            width: "160",
                            align: "center",
                            prop: "follw_time",
                            filters: [],
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.follw_time)),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            117668050
                          ),
                        },
                        [
                          _c("template", { slot: "header" }, [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  cursor: "pointer",
                                  color: "#1890FF",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.Activeshow("最后跟进日期")
                                  },
                                },
                              },
                              [_vm._v("最后跟进日期")]
                            ),
                          ]),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "学员来源", prop: "client_name" },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-table-column",
                        {
                          attrs: {
                            label: "意向专业",
                            prop: "ittnzy",
                            align: "center",
                          },
                        },
                        [
                          _c("template", { slot: "header" }, [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  cursor: "pointer",
                                  color: "#1890FF",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.Activeshow("意向专业")
                                  },
                                },
                              },
                              [_vm._v("意向专业")]
                            ),
                          ]),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "撞单次数", prop: "zdnum" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "学员级别", prop: "customer_level" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "报考项目", prop: "ittnxm" },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-table-column",
                        {
                          attrs: {
                            label: "组织部门",
                            prop: "structure",
                            align: "center",
                          },
                        },
                        [
                          _c("template", { slot: "header" }, [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  cursor: "pointer",
                                  color: "#1890FF",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.Activeshow("组织部门")
                                  },
                                },
                              },
                              [_vm._v("组织部门")]
                            ),
                          ]),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "el-table-column",
                        {
                          attrs: {
                            label: "客户标签",
                            prop: "labelpeer",
                            align: "center",
                          },
                        },
                        [
                          _c("template", { slot: "header" }, [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  cursor: "pointer",
                                  color: "#1890FF",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.Activeshow("客户标签")
                                  },
                                },
                              },
                              [_vm._v("客户标签")]
                            ),
                          ]),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "el-table-column",
                        {
                          attrs: {
                            width: "68",
                            label: "备注",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "itemDes",
                                        attrs: {
                                          content: scope.row.notes,
                                          "popper-class": "toolp",
                                          placement: "top-start",
                                          effect: "light",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "dib ell",
                                            staticStyle: { width: "48px" },
                                          },
                                          [_vm._v(_vm._s(scope.row.notes))]
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3724436026
                          ),
                        },
                        [
                          _vm._v(" "),
                          _c("template", { slot: "header" }, [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  cursor: "pointer",
                                  color: "#1890FF",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.Activeshow("备注")
                                  },
                                },
                              },
                              [_vm._v("备注")]
                            ),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                _vm._v(" "),
                _c(
                  "el-col",
                  {
                    staticClass: "toolbar",
                    staticStyle: { margin: "10px 0px" },
                    attrs: { span: 24 },
                  },
                  [
                    _c("pagination", {
                      attrs: {
                        "current-page": _vm.query.page,
                        totalPage: _vm.query.total,
                        pageSize: _vm.query.pagesize,
                      },
                      on: {
                        handleSizeChange: _vm.handleSizeChange,
                        handleCurrentChange: _vm.handleCurrentChange,
                      },
                    }),
                  ],
                  1
                ),
              ],
              2
            ),
            _vm._v(" "),
            _c("mailListUp", {
              ref: "staffSelect",
              attrs: {
                getType: _vm.get_type,
                radio: _vm.radio,
                condition: _vm.condition,
                visible: _vm.staffSelectVisible,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.staffSelectVisible = $event
                },
                SharedSustomersUp: _vm.staffSelectConfirm,
              },
              model: {
                value: _vm.staffResult,
                callback: function ($$v) {
                  _vm.staffResult = $$v
                },
                expression: "staffResult",
              },
            }),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                staticClass: "big-dialogs row_bg_jilu",
                attrs: { title: "跟进", visible: _vm.followUp },
                on: {
                  "update:visible": function ($event) {
                    _vm.followUp = $event
                  },
                },
              },
              [
                _c(
                  "el-row",
                  {
                    staticClass: "row-bg",
                    attrs: { type: "flex", justify: "center" },
                  },
                  [
                    _c("el-col", { attrs: { span: 12 } }, [
                      _vm.activities == ""
                        ? _c(
                            "h3",
                            {
                              staticStyle: {
                                color: "#ccc",
                                "text-align": "center",
                              },
                            },
                            [_vm._v("暂无记录")]
                          )
                        : _c(
                            "div",
                            [
                              _c("h3", [_vm._v("历史跟进记录:")]),
                              _vm._v(" "),
                              _c(
                                "el-timeline",
                                { attrs: { reverse: _vm.reverse } },
                                _vm._l(
                                  _vm.activities,
                                  function (activity, index) {
                                    return _c(
                                      "el-timeline-item",
                                      {
                                        key: index,
                                        attrs: {
                                          placement: "top",
                                          timestamp: activity.create_time,
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "eD8CF0 mr10" },
                                          [_vm._v(_vm._s(activity.type))]
                                        ),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(activity.create_realnaem)
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("p", [
                                          _vm._v(_vm._s(activity.content)),
                                        ]),
                                      ]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _c(
          "div",
          {
            staticClass: "boxImg",
            staticStyle: { width: "100%", padding: "20px" },
          },
          [_c("system", { attrs: { title: _vm.title, tab: _vm.tab } })],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }